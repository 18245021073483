import * as React from "react"

const NotFoundPage = () => {
  return (
    <main>
      404 not found
    </main>
  )
}

export default NotFoundPage
